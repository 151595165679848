@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Manrope&display=swap");

* {
  font-family: Manrope;
}

#root {
  height: 100vh;
}

.svg-pi-wrapper {
  position: relative;
}

.svg-pi {
  transform: rotate(-90deg);
}

.svg-pi-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.line-clamp-2 {
  display: -webkit-box;
  width: 240px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.slider::-webkit-slider-thumb {
  margin-top: -8px;
}

.slider::-webkit-slider-runnable-track {
  height: 1px;
  margin-top: 1px;
  margin-bottom: 1px;
}
